import styled from "styled-components";

export const SideBarAiWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: -22px;
  height: 100%;
  background-color: #e4dcef;
  background-color: ${(props) => (props.isNew ? "  rgb(0, 0, 0)" : "white")};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: 9999;
  cursor: pointer;
  .left-ai {
    height: 100%;
    width: 26px;
    background-color: ${(props) => (props.isNew ? "  #393939" : "#e4dcef")};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-right: 2px solid #989898a6;
    &:hover {
      width: 40px;
    }
  }
  .right-ai {
    padding: 10px;
    transition: all 400ms ease-in-out;
    width: 330px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .right-ai.close-ai {
    width: 0px;
  }
`;

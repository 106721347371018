import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation } from "react-router-dom";
import { SideBarAiWrapper } from "./SideBarAi.styles";
import globe from "../assets/image 278.png";

const SideBarAi = () => {
  const location = useLocation();
  const [isOpenAi, setIsOpenAi] = useState(false);
  return (
    <SideBarAiWrapper isNew={location.pathname.includes("/deloitte")}>
      <div className="left-ai" onClick={() => setIsOpenAi((prev) => !prev)}>
        <MoreVertIcon
          style={{
            fontSize: "66px",
            margin: "0",
            padding: "10px",
            color: location.pathname.includes("/deloitte") ? "white" : "",
          }}
        />
      </div>
      <div className={!isOpenAi ? "right-ai close-ai" : "right-ai "}>
        <div
          style={{
            width: "290px",
            // height: "160px",
            backgroundColor: "rgb(148, 148, 164)",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            color: "white",
            borderRadius: "20px",
            display: "flex",
            // alignItems: "center",
            // justifyCenter: "center",
            flexDirection: "column",
            padding: "30px 20px",
            // textAlign: "center",
          }}
        >
          <h2 style={{ margin: "0", color: "white" }}>Level 2</h2>
          <h3 style={{ margin: "0", color: "white" }}>Python Lesson 3 </h3>
          <h3 style={{ margin: "0", color: "white" }}>Class assignment due today.</h3>
        </div>
        <div
          style={{
            width: "290px",
            // height: "160px",
            backgroundColor: "rgb(103, 109, 118)",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            color: "white",
            borderRadius: "20px",
            display: "flex",
            // alignItems: "center",
            // justifyCenter: "center",
            flexDirection: "column",
            padding: "30px 20px",
            // textAlign: "center",
          }}
        >
          <h2 style={{ margin: "0", color: "white" }}>Level 4</h2>
          <h3 style={{ margin: "0", color: "white" }}>Computational </h3>
          <h3 style={{ margin: "0", color: "white" }}>Thinking Lesson 5</h3>
        </div>
        <div
          style={{
            width: "290px",
            // height: "160px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "20px",
            display: "flex",
            // alignItems: "center",
            justifyCenter: "center",
            flexDirection: "column",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          <img src={globe} alt="" srcset="" style={{ width: "100%" }} />
          <div
            style={{
              padding: "10px 10px",
            }}
          >
            <h3 style={{ margin: "0", fontSize: "14px" }}>Discover a course on example topic</h3>
            <p style={{ margin: "0", fontSize: "14px" }}>
              Horem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et
              velit interdum, ac aliquet odio mattis.
            </p>
          </div>
        </div>
      </div>
    </SideBarAiWrapper>
  );
};

export default SideBarAi;

import React from "react";
import ReactDOM from "react-dom";
//import axios from "axios";

// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/plugins/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-material-ui.scss?v1.0.0";
import { App } from "App";
import { ResearchApp } from "ResearchApp";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { getMsalConfig, getTenantName, handleHashRedirect } from "auth";
import { fetchTenantId } from "api/user";
import { Box } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// define the App type here -> 'ComputingEducation' or 'ResearchPortal'
var AppType = 'ComputingEducation';
export default AppType


if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
// console.log("==================================");
// console.log("==================================");
// console.log("==================================");
// If there's a auth redirect incoming, handle that redirect first before doing anything else
handleHashRedirect();

(async () => {
  ReactDOM.render(
    <Box
      style={{
        height: "100vh",
        textAlign: "center",
        paddingTop: "25vh",
        background: "white",
      }}
    >
      <img style={{ width: "150px" }} alt="" src="/loader.gif"></img>
    </Box>,
    document.querySelector("#root")
  );

  const tenantName = getTenantName();
  const tenant = await fetchTenantId(tenantName);

  // check if the url includes the string 'research'
  const searchString = 'research'; // Change this to your desired string
  const currentURL = window.location.href;
  
  if (currentURL.includes(searchString)) {
    console.log('The URL contains the specified string.');
    // Do something here when the string is found in the URL
    AppType='ResearchPortal';
    console.log(AppType);

  } else {
    console.log('The URL does not contain the specified string.');
    // Do something else here when the string is not found in the URL
    AppType='ComputingEducation';
    console.log(AppType);

  }

  // lock the AppType object so it cannot be altered anymore
  //Object.freeze(AppType);

  var msalInstance;

  if (tenant.id) {
    const config = getMsalConfig(tenant.id);

    msalInstance = new PublicClientApplication(config);

    

    // check if app type is computing education based on the URL
    if (msalInstance && AppType === 'ComputingEducation') {
      ReactDOM.render(
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </BrowserRouter>,
        document.querySelector("#root")
      );}
    else if (msalInstance && AppType === 'ResearchPortal') {
      ReactDOM.render(
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <MsalProvider instance={msalInstance}>
            <ResearchApp instance={msalInstance} />
          </MsalProvider>
        </BrowserRouter>,
        document.querySelector("#root")
      );}
     else {
      ReactDOM.render(
        <Box
          style={{
            height: "100vh",
            width: "100vw",
            textAlign: "center",
            paddingTop: "25vh",
            background: "white",
          }}
        >
          <img style={{ width: "150px" }} alt="" src="/loader.gif"></img>
        </Box>,
        document.querySelector("#root")
      );}}})();

import axios from "axios";
import useStore from "../zustand";

const useCurriculumApi = () => {
  const { authToken, selectedClassId, userId } = useStore((state) => state);

  const fetchSubjects = () => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/subjects/`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchCourses = (categoryId) => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/courses/`, {
      params: { category: categoryId },
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchLessons = (levelId) => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/curriculum_lessons/`, {
      params: { level: levelId },
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };
  const fetchActivityLessons = (classId) => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/lessons/`, {
      params: { class: classId },
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };
  const fetchLevels = (custom = false) => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/levels/`, {
      params: { custom: custom },
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchOfflineLevels = (courseId) => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/offline_levels/`, {
      params: { course: courseId },
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchOnlineLevels = (courseId) => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/online_levels/`, {
      params: { course: courseId },
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchLevel = (levelId) => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/levels/${levelId}/`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchCustomLevel = (levelId) => {
    if (levelId !== undefined) {
      return axios.get(
        `${process.env.REACT_APP_API_PATH}/curriculum/api/custom_levels/${levelId}/`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
    }
  };

  const fetchLevelResources = (levelId) => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/resources/`, {
      params: { level: levelId },
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchLessonResources = (lessonId) => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/resources/`, {
      params: { lesson: lessonId },
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const deleteLevel = (levelId) => {
    return axios.delete(`${process.env.REACT_APP_API_PATH}/curriculum/api/levels/${levelId}/`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchResource = (resourceId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/curriculum/api/resource_url/${resourceId}/`,
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
  };

  const fetchPermission = () => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/permissions`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchAvailableLevels = () => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/available_levels/`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };
  // ==========================

  const fetchMarkLessonComplete = (lessonId, classId, isComplete) => {
    let body = {
      isComplete: isComplete,
    };
    return axios.post(
      `${process.env.REACT_APP_API_PATH}/users/api/mark_lesson_as_complete/${lessonId}/${classId}/`,
      body,
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
  };
  const fetchCheckLessonComplete = (lessonId, classId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/users/api/check_lesson_is_complete/${lessonId}/${classId}/`,

      { headers: { Authorization: `Bearer ${authToken}` } }
    );
  };
  // ===========================

  const fetchCurriculumDetails = (type, id) => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/curriculum_details/`, {
      params: { type: type, id: id },
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchActivityName = (lesson) => {
    if (lesson !== undefined) {
      return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/exercises/`, {
        params: { lesson: lesson },
        headers: { Authorization: `Bearer ${authToken}` },
      });
    }
  };

  const fetchH5PToken = (classId, lessonId, exerciseId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/api/h5p_player_token`, {
        params: {
          class_id: classId,
          lesson_id: lessonId,
          exercise_id: exerciseId,
        },
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((result) => {
        return result.data;
      });
  };

  const fetchTopics = () => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/computational_concepts/`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchLearningObjectives = () => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/learning_objectives/`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };
  const fetchLessonPlan = () => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/lessonplans/`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };
  const fetchLessonPlanId = (id) => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/lessonplans/${id}`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };
  const updateCustomLessonPlanId = (id, body) => {
    // console.log("id :>> ", id);
    // console.log("body :>> ", body);
    return axios.put(`${process.env.REACT_APP_API_PATH}/curriculum/api/lessonplans/${id}/`, body, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };
  const updateCustomLessonPlanIdMutation = (body) => {
    // console.log("body.id :>> ", body.id);
    // console.log("body.body :>> ", body.body);
    return axios.put(
      `${process.env.REACT_APP_API_PATH}/curriculum/api/lessonplans/${body.id}/`,
      body.body,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
  };
  const deleteCustomLessonPlanId = (levelId) => {
    return axios.delete(
      `${process.env.REACT_APP_API_PATH}/curriculum/api/lessonplans/${levelId}/`,
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
  };
  const createLessonPlan = (body) => {
    console.log("body :>> ", body);
    return axios.post(`${process.env.REACT_APP_API_PATH}/curriculum/api/lessonplans/`, body, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const fetchLessonObjectives = (lessonId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/curriculum/api/lessons/${lessonId}/learning_objectives/`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
  };

  const fetchAllH5PQuestions = () => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/h5pfiles`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  const sendBlockCodeClass = (code, state) => {
    // console.log("sendBlockCodeClass code", code);
    // console.log("sendBlockCodeClass state", state);
    return axios.post(
      `${process.env.REACT_APP_API_PATH}/curriculum/api/classes/${selectedClassId}/exercise_results/`,
      {
        params: { code: code, jsonBlock: state },
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
  };
  const sendBlockCodeStudent = (code, state) => {
    // console.log("sendBlockCodeStudent code", code);
    // console.log("sendBlockCodeStudent state", state);
    return axios.post(
      `${process.env.REACT_APP_API_PATH}/curriculum/api/students/${userId}/exercise_results/`,
      {
        params: { code: code, jsonBlock: state },
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
  };

  const fetchH5PQuestion = (
    userId,
    exerciseId,
    tempExerciseId,
    sessionId,
    selectedClassId,
    tenantId,
    lessonId,
    h5pLocation,
    h5pResult,
    resultIframeOutput
  ) => {
    console.log("fetch parameters:");
    console.log("studentId", userId);
    console.log("tempExerciseId", tempExerciseId);
    console.log("exerciseId",exerciseId);
    console.log("sessionId", sessionId);
    console.log("classId", selectedClassId);
    console.log("schoolId", tenantId);
    console.log("lessonId", lessonId);
    console.log("h5pLocation", h5pLocation);
    console.log("h5pResult", h5pResult);
    console.log("resultIframeOutput :>> ", resultIframeOutput);
    let body;
    if (h5pResult) {
      body = JSON.stringify({
        student_id: userId,
        exercise_id: exerciseId,
        session_id: sessionId,
        class_id: selectedClassId,
        school_id: tenantId,
        lesson_id: lessonId,
        h5p_location_id: h5pLocation,
        result: JSON.parse(h5pResult),
        student_answer: resultIframeOutput,
        ts: new Date().toISOString(),
      });
    } else {
      body = JSON.stringify({
        student_id: userId,
        exercise_id: exerciseId,
        session_id: sessionId,
        class_id: selectedClassId,
        school_id: tenantId,
        lesson_id: lessonId,
        // h5p_location_id: h5pLocation,
        // result: h5pResult,
        ts: new Date().toISOString(),
      });
    }

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return axios.post(
      `${process.env.REACT_APP_H5PQUESTIONSERVERURL}/api/v1/get_next_question`,
      //`https://h5pquestionserverdev.azurewebsites.net/api/v1/get_next_question`,
      body,
      headers
    );
  };
  const fetchH5PQuestionDetails = (exerciseId, userId) => {
    const body = JSON.stringify({
      exercise_id: exerciseId,
      student_id: userId,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return axios.post(
      `${process.env.REACT_APP_H5PQUESTIONSERVERURL}/api/v1/get_exercise_details`,
      //`https://h5pquestionserverdev.azurewebsites.net/api/v1/get_exercise_details`,
      body,
      headers
    );
  };
  const fetchLessonIdForExercise = (exerciseId, userId, selectedClassId) => {
    const body = JSON.stringify({
      exercise_id: exerciseId,
      student_id: userId,
      class_id: selectedClassId,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return axios.post(
      `${process.env.REACT_APP_H5PQUESTIONSERVERURL}/api/v1/get_lesson_id_from_exercise_id`,
      body,
      headers
    );
  };

  const fetchBlocksExerciseQuestion = (exerciseId) => {
    const body = JSON.stringify({
      exercise_id: exerciseId,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return axios.post(
      `${process.env.REACT_APP_BlOCKQUESTIONSERVERURL}/api/v1/get_block_coding_exercise_data`,
      body,
      headers
    );
  };

  const fetchBlocksSubmitAnswer = (exerciseId, json_workspace, code) => {
    const body = JSON.stringify({
      exercise_id: exerciseId,
      json_workspace: json_workspace,
      python_code: code,
      student_id: userId,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return axios.post(
      `${process.env.REACT_APP_BlOCKQUESTIONSERVERURL}/api/v1/score_block_coding_exercise_workspace`,
      body,
      headers
    );
  };
  const submitBlockAnswer = (exerciseId, jsonWorkspace, code) => {
    const body = JSON.stringify({
      exercise_id: exerciseId,
      json_workspace: jsonWorkspace,
      python_code: code,
      student_id: userId,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return axios.post(
      `${process.env.REACT_APP_BlOCKQUESTIONSERVERURL}/api/v1/submit_solution`,
      body,
      headers
    );
  };
  // cons
  const fetchBlocksQuestionHint = (exerciseId, json_workspace, code) => {
    const body = JSON.stringify({
      exercise_id: exerciseId,
      json_workspace: json_workspace,
      python_code: code,
      student_id: userId,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return axios.post(
      `${process.env.REACT_APP_BlOCKQUESTIONSERVERURL}/api/v1/get_hint`,
      body,
      headers
    );
  };
  // const fetchBlocksQuestionScore = (
  //   exerciseId,
  //   json_workspace,
  //   python_code
  // ) => {
  //   const body = JSON.stringify({
  //     exercise_id: exerciseId,
  //     json_workspace: json_workspace,
  //     python_code: python_code,
  //     student_id: userId,
  //   });
  //   const headers = {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //   };
  //   return axios.post(
  //     `${process.env.REACT_APP_BlOCKQUESTIONSERVERURL}/api/v1/score_block_coding_exercise_workspace`,
  //     body,
  //     headers
  //   );
  // };
  return {
    fetchSubjects,
    fetchCourses,
    fetchLessons,
    fetchActivityLessons,
    fetchH5PQuestion,
    fetchH5PQuestionDetails,
    fetchLevels,
    fetchOfflineLevels,
    fetchOnlineLevels,
    fetchLevel,
    fetchCustomLevel,
    fetchLevelResources,
    fetchLessonResources,
    fetchResource,
    fetchAllH5PQuestions,
    deleteLevel,
    fetchPermission,
    fetchAvailableLevels,
    fetchCurriculumDetails,
    fetchH5PToken,
    fetchTopics,
    fetchLearningObjectives,
    fetchLessonObjectives,
    fetchActivityName,
    fetchLessonIdForExercise,
    sendBlockCodeStudent,
    sendBlockCodeClass,
    fetchBlocksExerciseQuestion,
    fetchBlocksSubmitAnswer,
    fetchBlocksQuestionHint,
    fetchLessonPlan,
    createLessonPlan,
    fetchLessonPlanId,
    updateCustomLessonPlanId,
    deleteCustomLessonPlanId,
    submitBlockAnswer,
    fetchMarkLessonComplete,
    fetchCheckLessonComplete,
    updateCustomLessonPlanIdMutation,
    // fetchBlocksQuestionScore,
  };
};

export default useCurriculumApi;

import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Box } from "@material-ui/core";

const SidebarItem = ({ route, isSidebarOpen, index, isSidebarText }) => {
  const location = useLocation();
  const MINUTE_MS = 60000;

  return (
    <Link
      to={`/activities${route.path}`}
      style={{
        display: "flex",
        justifyContent: !isSidebarOpen ? "center" : "",
        alignItems: "center",
        gap: "20px",
        padding: "11px 20px",
        borderRadius: "4px",
        margin: "2px 4px",
        // width: "250px",
        cursor: "pointer",
        position: "relative",
        backgroundColor:
          index === 0 && location.pathname.replace("/activities", "").length === 1
            ? "#6358a523"
            : index !== 0 &&
              location.pathname
                .replace("/activities", "")
                .includes(`${route.path.replace("/", "")}`)
            ? "#6358a523"
            : "",
        color:
          index === 0 && location.pathname.replace("/activities", "").length === 1
            ? "#6358A5"
            : index !== 0 &&
              location.pathname
                .replace("/activities", "")
                .includes(`${route.path.replace("/", "")}`)
            ? "#6358A5"
            : "#cbbfd0",
      }}
      //   onClick={() => console.log("route.path", `/activities${route.path}`)}
    >
      {index === 0 && location.pathname.replace("/activities", "").length === 1 ? (
        <div
          style={{
            position: "absolute",
            background: location.pathname.includes("deloitte") ? "black" : "#6358A5",
            height: "110%",
            width: "9px",
            borderRadius: "2px",
            top: "-5%",
            left: 0,
          }}
        ></div>
      ) : index !== 0 &&
        location.pathname.replace("/activities", "").includes(`${route.path.replace("/", "")}`) ? (
        <div
          style={{
            position: "absolute",
            background: location.pathname.includes("deloitte") ? "rgb(134, 188, 37)" : "#6358A5",
            height: "110%",
            width: "9px",
            borderRadius: "2px",
            top: "-5%",
            left: 0,
          }}
        ></div>
      ) : null}

      <Box
        component={route.icon}
        style={{
          color:
            location.pathname
              .replace("/activities", "")
              .includes(`${route.path.replace("/", "")}`) && location.pathname.includes("deloitte")
              ? "rgb(134, 188, 37)"
              : "grey",

          color:
            location.pathname.includes("deloitte") &&
            index !== 0 &&
            location.pathname.replace("/activities", "").includes(`${route.path.replace("/", "")}`)
              ? "rgb(134, 188, 37)"
              : location.pathname.includes("deloitte")
              ? "grey"
              : "",
        }}
      />
      {isSidebarText ? (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            margin: "0",
            //   display: !isSidebarOpen ? "none" : "inline",
            color:
              location.pathname.includes("deloitte") &&
              index !== 0 &&
              location.pathname
                .replace("/activities", "")
                .includes(`${route.path.replace("/", "")}`)
                ? "rgb(134, 188, 37)"
                : location.pathname.includes("deloitte")
                ? "grey"
                : "",
          }}
        >
          {route.name}
        </div>
      ) : null}
    </Link>
  );
};

export default SidebarItem;

import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
// import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons components
import DirectionsRun from "@material-ui/icons/DirectionsRun";

// core components
import componentStyles from "assets/theme/components/dropdowns/user-dropdown.js";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
// import { handleLogout } from "auth";
import useStore from "./../../zustand";
import { handleLogout } from "auth";
// import { request } from "auth";

const useStyles = makeStyles(componentStyles);

export default function UserDropdownTwo({ children }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { instance, accounts, inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  if (inProgress === "none" && !isAuthenticated) {
    // instance.loginRedirect(request);
  }

  const [name, setName] = useState(null);

  const { userIcon } = useStore((state) => state);

  // console.log("this is accounts: ", accounts && accounts);
  useEffect(() => {
    setName(accounts[0] && accounts[0].name);
  }, [inProgress]);

  const menuId = "dropdowns-user-dropdown-id";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right", top: "40px" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <Divider component="div" classes={{ root: classes.dividerRoot }} /> */}
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={() => {
          handleMenuClose();
          handleLogout(instance);
        }}
      >
        <Box
          component={DirectionsRun}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>Logout</span>
      </Box>
    </Menu>
  );

  return (
    <>
      <div
        // edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        // color="inherit"
        // classes={{
        //   label: classes.buttonLabel,
        //   root: classes.buttonRoot,
        // }}
        style={{ cursor: "pointer" }}
      >
        <Hidden mdDown>
          <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
          <UnauthenticatedTemplate>{accounts}</UnauthenticatedTemplate>
        </Hidden>
      </div>
      {renderMenu}
    </>
  );
}

import React, { useState } from "react";
import StudentPageHeader from "./HomePageComponents/StudentPageHeader";
import useStore from "zustand";
import SideBarAi from "./HomePageComponents/SideBarAi";
// import NextActivity from "./HomePageComponents/NextActivity";
import progImage from "../studentpage/assets/Frame 86.png";
import ava1 from "../studentpage/assets/Group 2277.png";
import ava2 from "../studentpage/assets/Group 2279.png";
import ava3 from "../studentpage/assets/Group 2282.png";
import SidebarNew from "./SidebarNew";

import { StudentHomePageWrapper } from "./StudentHomepage.styles";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
const DeloittePage = () => {
  const [selectedClass, setSelectedClass] = useState("");

  const { selectedClassId, setSelectedClassId } = useStore();
  const [windowSize, setWindowSize] = React.useState(getWindowSize());

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        overflowX: "hidden",
        marginTop: "-10px",
      }}
    >
      <StudentHomePageWrapper>
        <StudentPageHeader title={"homepage"} />
        <div style={{ display: "flex", gap: "30px", margin: "20px 0", flexWrap: "wrap" }}>
          <div
            className="item-activity-card"
            style={{
              //   width: windowSize.innerWidth > 955 && windowSize.innerWidth < 1380 ? "280px" : "100%",
              maxWidth: "320px",
              height: "220px",
              backgroundColor: "rgb(148, 148, 164)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "20px",
              display: "flex",
              //   alignItems: "space-between",
              justifyContent: "space-between",
              flexDirection: "column",
              padding: "20px ",
              //   textAlign: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                color: "white",
                top: "4px",
                right: "20px",
                fontSize: "2rem",
              }}
            >
              <MoreHorizIcon style={{ fontSize: "42px" }} />
            </div>
            <h1
              style={{
                margin: "0",
                color: "white",
                fontWeight: "500",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                // justifyCenter: "center",
                gap: "6px",
              }}
            >
              <WarningAmberIcon />
              Due Today
            </h1>
            <p style={{ margin: "0", color: "white", fontWeight: "600" }}>
              Complete your Python Level 2 Lesson 3 Activity - Selection (If Statements).
            </p>
            <div
              style={{
                margin: "0",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <BorderColorIcon />
                <button
                  style={{
                    padding: "4px 18px",
                    border: "none",
                    color: "rgb(97, 88, 160)",
                    background: "white",
                    fontWeight: "600",
                    borderRadius: "2px",
                  }}
                >
                  {" "}
                  Continue
                </button>
              </div>

              <AvatarGroup max={4}>
                <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src={ava1} />
                <Avatar sx={{ width: 24, height: 24 }} alt="Travis Howard" src={ava2} />
                <Avatar sx={{ width: 24, height: 24 }} alt="Cindy Baker" src={ava3} />
              </AvatarGroup>
            </div>
          </div>
          <div
            className="item-activity-card"
            style={{
              //   width: windowSize.innerWidth > 955 && windowSize.innerWidth < 1380 ? "280px" : "100%",
              maxWidth: "280px",
              height: "220px",
              backgroundColor: "rgb(148, 148, 164)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "20px",
              display: "flex",
              //   alignItems: "space-between",
              justifyContent: "space-between",
              flexDirection: "column",
              padding: "20px ",
              //   textAlign: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                color: "white",
                top: "4px",
                right: "20px",
                fontSize: "2rem",
              }}
            >
              <MoreHorizIcon style={{ fontSize: "42px" }} />
            </div>
            <h1
              style={{
                margin: "0",
                color: "white",
                fontWeight: "500",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                // justifyCenter: "center",
                gap: "6px",
                opacity: 0,
              }}
            >
              Due Today
            </h1>
            <p style={{ margin: "0", color: "white", fontWeight: "600" }}>
              Tmsk Title Corem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <div
              style={{
                margin: "0",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <BorderColorIcon />
                <button
                  style={{
                    padding: "4px 18px",
                    border: "none",
                    color: "rgb(97, 88, 160)",
                    background: "white",
                    fontWeight: "600",
                    borderRadius: "2px",
                  }}
                >
                  {" "}
                  Start
                </button>
              </div>

              <AvatarGroup max={4}>
                <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src={ava1} />
              </AvatarGroup>
            </div>
          </div>
          <div
            className="item-activity-card"
            style={{
              //   width: windowSize.innerWidth > 955 && windowSize.innerWidth < 1380 ? "280px" : "100%",
              maxWidth: "280px",
              height: "220px",
              backgroundColor: "rgb(148, 148, 164)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "20px",
              display: "flex",
              //   alignItems: "space-between",
              justifyContent: "space-between",
              flexDirection: "column",
              padding: "20px ",
              //   textAlign: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                color: "white",
                top: "4px",
                right: "20px",
                fontSize: "2rem",
              }}
            >
              <MoreHorizIcon style={{ fontSize: "42px" }} />
            </div>
            <h1
              style={{
                margin: "0",
                color: "white",
                fontWeight: "500",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                // justifyCenter: "center",
                gap: "6px",
                opacity: "0",
              }}
            >
              <WarningAmberIcon />
              Due Today
            </h1>
            <p style={{ margin: "0", color: "white", fontWeight: "600" }}>
              Task Title Corem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <div
              style={{
                margin: "0",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <BorderColorIcon />
                <button
                  style={{
                    padding: "4px 18px",
                    border: "none",
                    color: "rgb(97, 88, 160)",
                    background: "white",
                    fontWeight: "600",
                    borderRadius: "2px",
                  }}
                >
                  {" "}
                  Start
                </button>
              </div>

              <AvatarGroup max={4}>
                <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src={ava1} />
                <Avatar sx={{ width: 24, height: 24 }} alt="Travis Howard" src={ava2} />
              </AvatarGroup>
            </div>
          </div>
          <div
            className="item-activity-card"
            style={{
              //   width: windowSize.innerWidth > 955 && windowSize.innerWidth < 1380 ? "280px" : "100%",
              maxWidth: "280px",
              height: "220px",
              backgroundColor: "rgb(148, 148, 164)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "20px",
              display: "flex",
              //   alignItems: "space-between",
              justifyContent: "space-between",
              flexDirection: "column",
              padding: "20px ",
              //   textAlign: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                color: "white",
                top: "4px",
                right: "20px",
                fontSize: "2rem",
              }}
            >
              <MoreHorizIcon style={{ fontSize: "42px" }} />
            </div>
            <h1
              style={{
                margin: "0",
                color: "white",
                fontWeight: "500",
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                // justifyCenter: "center",
                gap: "6px",
                opacity: "0",
              }}
            >
              <WarningAmberIcon />
              Due Today
            </h1>
            <p style={{ margin: "0", color: "white", fontWeight: "600" }}>
              Task Title Corem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <div
              style={{
                margin: "0",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <BorderColorIcon />
                <button
                  style={{
                    padding: "4px 18px",
                    border: "none",
                    color: "rgb(97, 88, 160)",
                    background: "white",
                    fontWeight: "600",
                    borderRadius: "2px",
                  }}
                >
                  {" "}
                  Start
                </button>
              </div>

              <AvatarGroup max={4}>
                <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src={ava1} />
                <Avatar sx={{ width: 24, height: 24 }} alt="Cindy Baker" src={ava3} />
              </AvatarGroup>
            </div>
          </div>
        </div>

        <div
          //   className="image-landing"
          style={{
            width: "100%",
            overflow: "auto",
            // borderRadius: "18px",
            marginTop: "40px",
            // height: "440px",
            // overflowX: "visible",
          }}
        >
          <h3 style={{ margin: "10px 0", color: "white" }}>Your Progression</h3>

          <img
            src={progImage}
            alt=""
            srcSet=""
            style={{
              width: "100%",
              padding: "0",
              // height: "95%",
              margin: "0",
            }}
          />
        </div>
      </StudentHomePageWrapper>
      {false && <SidebarNew />}
      <SideBarAi />
    </div>
  );
};

export default DeloittePage;

import React, { useState } from "react";
import { SidebarNewWrapper } from "./SidebarNew.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMsal } from "@azure/msal-react";
import { useLocation, Link } from "react-router-dom";
import useStore from "../../../zustand";
import SidebarItem from "./SidebarItem";
import usePersistStore from "../../../zustandPersist";
import UserDropdownTwo from "components/Dropdowns/UserDropdownTwo";
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
const SidebarNew = ({ routes }) => {
  const { userId, authToken, userIcon } = useStore();
  const { accounts } = useMsal();
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSidebarText, setIsSidebarText] = useState(true);
  const { permission } = usePersistStore();
  const location = useLocation();
  console.log("accounts :>> ", accounts[0].name.split(" ")[0].split());
  console.log("accounts :>> ", accounts[0]);
  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const handleSidebar = () => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
      setIsSidebarText(false);
    } else {
      setIsSidebarOpen(true);
      setTimeout(() => setIsSidebarText(true), 300);
    }
  };
  React.useEffect(() => {
    if (windowSize.innerWidth < 1430) {
      setIsSidebarOpen(false);
      setIsSidebarText(false);
    }
  }, [windowSize.innerWidth]);
  console.log("windowSize.innerWidth ===========:>> ", windowSize.innerWidth);
  console.log("windowSize.innerWidth ===========:>> ", windowSize.innerWidth);
  console.log("windowSize.innerWidth ===========:>> ", windowSize.innerWidth);
  console.log("windowSize.innerWidth ===========:>> ", windowSize.innerWidth);
  return (
    <SidebarNewWrapper
      isSidebarOpen={isSidebarOpen}
      isNew={location.pathname.includes("/deloitte")}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto 30px auto ",
            width: "100%",
          }}
        >
          <Link
            to={`/data/school`}
            disabled={location.pathname.includes("/deloitte")}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#4a4567",
              color: "white",
              border: "2px solid #6357A5",
              // marginLeft: "5px",
              padding: "5px 10px",
              borderRadius: "4px",
              cursor: "pointer",
              opacity: location.pathname.includes("/deloitte") ? "0" : "1",
            }}
          >
            {isSidebarText ? "Go back to Class" : "Class"}
          </Link>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // width: "65px",
            // height: "65px",
            // borderRadius: "1000px",
            // margin: "0 auto 20px",
            // position: "relative",
          }}
        >
          <UserDropdownTwo account={accounts}>
            <div
              style={{
                border: location.pathname.includes("/deloitte")
                  ? "2px solid rgb(134, 188, 37)"
                  : "4px solid #6258A5",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "65px",
                height: "65px",
                borderRadius: "1000px",
                margin: "0 auto 20px",
                position: "relative",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "22px",
                  color: location.pathname.includes("/deloitte") ? "rgb(31, 111, 10)" : "#6258A5",
                }}
              >
                {accounts[0].name.split(" ")[0].split()[0].charAt(0)}
                {accounts[0].name.split(" ")[1].split()[0].charAt(0)}
              </div>
              {userIcon && (
                <div
                  style={{
                    position: "absolute",
                    width: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bottom: 0,
                    right: 0,
                    background: location.pathname.includes("/deloitte")
                      ? "rgb(57, 57, 57)"
                      : "white",
                    borderRadius: "1000px",
                    border: location.pathname.includes("/deloitte")
                      ? "2px solid rgb(134, 188, 37)"
                      : "2px solid #6258A5",
                    transform: "translate(50%,3px)",
                  }}
                >
                  <img src={userIcon} alt="" style={{ width: "100%" }} />
                </div>
              )}
            </div>
          </UserDropdownTwo>
        </div>
        <div>
          {routes.map((route, index) => (
            <SidebarItem
              route={route}
              isSidebarOpen={isSidebarOpen}
              index={index}
              isSidebarText={isSidebarText}
            />
          ))}
        </div>
      </div>
      <div
        className="end-new-side-bar"
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "45px 15px",
          alignItems: "center",
          // margin: "5px 10px",
          // width: "250px",
          width: "100%",
          cursor: "pointer",
          gap: "10px",
          color: location.pathname.includes("/deloitte") ? "rgb(134, 188, 37)" : "white",
        }}
        onClick={() => {
          handleSidebar();
        }}
      >
        {isSidebarText ? (
          <>
            <ArrowBackIcon
              style={{
                backgroundColor: location.pathname.includes("/deloitte")
                  ? "rgb(134, 188, 37)"
                  : "#6358A5",
                borderRadius: "200px",
                // padding: "20px",
                width: "30px",
                height: "30px",
                color: location.pathname.includes("/deloitte") ? "rgb(57, 57, 57)" : "white",
              }}
            />{" "}
            Hide menu
          </>
        ) : (
          <ArrowForwardIcon
            style={{
              backgroundColor: location.pathname.includes("/deloitte")
                ? "rgb(134, 188, 37)"
                : "#6358A5",
              borderRadius: "200px",
              // padding: "20px",
              width: "30px",
              height: "30px",
              color: location.pathname.includes("/deloitte") ? "rgb(57, 57, 57)" : "white",
            }}
          />
        )}
      </div>
    </SidebarNewWrapper>
  );
};

export default SidebarNew;

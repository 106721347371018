import styled from "styled-components";

export const SidebarNewWrapper = styled.div`
  /* position: fixed;
  top: 0; */
  height: 100%;
  background-color: ${(props) => (props.isNew ? "rgb(57, 57, 57)" : "white")};
  align-items: start;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isSidebarOpen ? "start" : "center")};
  justify-content: space-between;
  width: ${(props) => (props.isSidebarOpen ? "280px" : "102px")};
  padding: 60px 0 0 0;
  min-height: 100vh;
  z-index: 9999999999;
  border-right: ${(props) => (props.isNew ? " 2px solid #ffffff" : "")};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: width 300ms ease-in-out;

  .left-ai {
    height: 100%;
    width: 30px;
    background-color: #e4dcef;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid #989898a6;
    &:hover {
      width: 40px;
    }
  }
  .right-ai {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .right-ai.close-ai {
  }
  .end-new-side-bar {
  }
`;
